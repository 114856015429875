@import "../../../general";

.tools_consult_chat_dashboard_wrapper {
  @include verticalFlex();
  color: var(--white);
  // padding: 1vh;
  overflow: auto;

  @include media-large-device() {
    padding: 2vh 1vh 1vh 1vh;
  }

  @include media-small-desktop() {
    padding: 2vh;
  }
}

.tools_consult_back_icon_btn_wrapper {
  position: absolute;
  top: 2vh;
  left: 1vh;
  z-index: 2;
  font-size: 1rem;

  @include media-large-device() {
    left: 0vh;
  }

  // @include media-small-desktop() {
  //   left: 2vh;
  // }
}

.tools_consult_menu_btn_wrapper {
  position: absolute;
  top: 2vh;
  right: 1vh;
  z-index: 2;
  display: block;

  @include media-large-device() {
    right: 2vh;
    display: none;
  }
}

.tools_consult_chat_wrapper {
  // @include verticalFlex();
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 1vh;
  padding-top: 7vh;
  height: 100%;

  @include media-small-device() {
    padding: 1vh 7vh;
    padding-top: 7vh;
  }

  @include media-large-device() {
    padding-left: 3vh;
    padding-top: 0vh;
    padding-right: 0vh;
  }

  @include media-small-desktop() {
    padding-left: 5vh;
  }
}

.tools_consult_chat_message_wrapper {
  @include verticalFlex();
  overflow: auto;
  scroll-behavior: smooth;
}