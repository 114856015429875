@import "../../../../../general";

.tool_consult_file_donwload_dropdown {
  @include horizontalFlex($justify: flex-end, $width: auto);
  // position: relative;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-template-rows: 1fr;
  // gap: 1vh;

  .tool_consult_file_donwload_img {
    position: relative;
    cursor: pointer;
    width: auto;
    // height: 1.5rem;
    height: 100%;
    width: 1.5rem;

    @include media-xl() {
      width: 1.8rem;
    }

    // @include media-mid-device() {
    //   height: auto;
    // }
    // @include media-large-device() {
    //   height: 1.5rem;
    // }
    // @include media-small-desktop() {
    //   max-height: 1.8rem;
    //   height: auto;
    // }

    // @include media-xl() {
    //   height: 2rem;
    // }
  }

  .tool_consult_file_donwload_dropdown_btn {
    @include horizontalFlex($justify: center);
    border-radius: 4px;
    // height: 1.5rem;
    border: none;
    background-color: var(--white);
    padding: 0.5vh;
    @include stc-normal-font($fontColor: var(--dark-blue));
    width: 2rem;
    height: 100%;
    // @include media-small-device() {
    //   height: 1.5rem;
    // }

    // @include media-mid-device() {
    //   height: auto;
    // }
    @include media-large-device() {
      width: auto;
    }

    // @include media-small-desktop() {
    //   height: 1.8rem;
    // }

    span {
      @include media-potrait() {
        display: none;
      }
    }
  }

  .tool_consult_file_donwload_dropdown_wrapper {
    @include verticalFlex($width: auto, $align: flex-start);
    position: absolute;
    right: 1px;
    top: 40px;
    white-space: nowrap;
    z-index: 5;
    padding: 1vh;
    background-color: var(--white);
    border-radius: 8px;
    @include stc-normal-font($fontColor: var(--dark-blue));
    box-shadow: 0px 0px 20px 0px #11294940;

    @include media-small-device() {
      top: 28px;
    }

    @include media-mid-device() {
      top: 40px;
    }

    @include media-large-device() {
      top: 28px;
      border-radius: 4px;
    }

    @include media-small-desktop() {
      top: 35px;
      border-radius: 4px;
    }

    @include media-xl() {
      top: 40px;
    }
  }

  .tool_consult_file_donwload_dropdown_text {
    @include horizontalFlex($justify: flex-start);
    cursor: pointer;

    @include stc-normal-font($fontColor: var(--dark-blue));
  }
}