@import "../../../general";

.tools_consult_file_pane {
  @include verticalFlex($justify: flex-start);
  color: var(--white);
  background: rgba(14, 34, 61, 0.5);
  position: absolute;
  right: -100vw;
  transition: left 0.4s;
  height: 100%;
  background: #0a182b;
  transition: 300ms;
  z-index: 4;
  overflow: hidden;
  max-height: 100%;

  @include media-large-device() {
    position: relative;
    right: 0;
    height: auto;
    border-left: 1px solid rgba(57, 77, 103, 1);
  }
}

.tools_consult_file_pane_show {
  @include media-potrait() {
    right: 0px;
  }
}

.tools_consult_btn_sideline {
  position: absolute;
  left: 5vh;
  top: 1vh;
  height: 3.7vh;
  border-right: 1px solid rgba(57, 77, 103, 1);

  @include media-large-device() {
    display: none;
  }
}

.tools_consult_file_pane_tab_btn_wrapper {
  @include horizontalFlex($gap-size: 0vh);

  @include media-potrait() {
    border-top: 1px solid rgba(57, 77, 103, 1);
    border-bottom: 1px solid rgba(57, 77, 103, 1);
  }
}

.tools_consult_file_pane_tab_btn {
  @include verticalFlex();
  @include stc-normal-font($fontColor: var(--white), $bold: 700);
  background: none;
  border: none;
  padding: 1vh;
}

.tools_consult_unactive_tab {
  @include media-large-device() {
    border-bottom: 2px solid rgba(57, 77, 103, 1);
  }
}

.tools_consult_active_tab {
  border-bottom: 2px solid var(--white);
}

.tools_consult_back_icon_btn {
  background: none;
  border: none;
  display: block;
  color: var(--white);
  font-size: var(--m-font);
  font-size: 1.2rem;

  @include media-350p() {
    width: 1.5rem;
  }

  @include media-large-device() {
    font-size: 1rem;
  }

  @include media-small-desktop() {
    font-size: 1.5rem;
  }

  @include media-large-device() {
    padding: 0rem 0.5rem;
  }
}

.tools_consult_back_icon_btn_border {
  border-right: 1px solid rgba(57, 77, 103, 1);
  @include horizontalFlex($width: auto);
  padding: 0rem 0.5rem;

  @include media-large-device() {
    display: none;
  }
}